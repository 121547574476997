import { reactive } from "vue";

const theme = reactive({
  agentLogo: "zagga.png",
  devLogo: "zagga-logo.jpg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "faff2a0b-2f5a-4f69-959c-e336ec9163f4",
  videoLibraryId: "318860",
  streamCdnUrl: "https://vz-c4939c36-13a.b-cdn.net",
  streamApiKey: "cacfc228-715a-4c21-a487f74bc10b-455f-4c49",
  storageApiPassword: "4e177998-1056-4635-b68590baca0a-e1ad-4f15",
  cdnUrl: "https://zagga.b-cdn.net",
  devFolder: "/zagga",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/4k_dashboard.mp4",

  // top level menu
  assetsMenuOrder: [
    'vista-penthouse',
  ],

  vistaPenthouseMenuOrder: [
    'videos',
    'virtual-tour',
    'renders'
  ],
  bed2MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  bed3MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  lobbyMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  clubvistaMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  rooftopMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],

  vistaPenthouseAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Vista Penthouse'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/fYfrUFgvu'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  bed2Asset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      '2 Bed'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/B3dD7AGxu'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  bed3Asset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      '3 Bed'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/5phsXhF-B'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  lobbyAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Lobby'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/7URp-vZfj'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  clubvistaAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Club Vista'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/FiQjeluKt'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  rooftopAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
});

export default { theme };

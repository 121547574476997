<template>
  <ViewTopNav :heading="this.viewType" :subheading="this.assetType" />

  <div class="position-absolute p-content z-1">
    <div class="col-12 p-0 z-1 h-100">
      <div class="row g-0 h-100">




        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" v-if="viewType === 'renders'">
          <div class="carousel-indicators">
            <button v-for="(image, index) in assetTypes" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ active: index === 0 }" aria-current="index === 0 ? 'true' : 'false'" :aria-label="'Slide ' + (index + 1)"></button>
          </div>
          <div class="carousel-inner">
            <div
              v-for="(asset, index) in assetTypes"
              :key="index"
              class="carousel-item"
              :class="{ active: index === 0 }"
            >
            <img
            :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
            class="img-fluid">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>


              
        <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid" v-if="viewType != 'renders'">
          <div class="row g-0">
            <div class="col-12 position-relative z-1">

              <!-- Videos -->
              <router-link v-if="viewType === 'videos' && collectionId" :to="{
                  name: 'VideoView',
                  params: {
                    guid: asset.guid,
                    collectionId: asset.videoLibraryId
                  }
                }" class="v-link">
                              <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'"
                                class="img-fluid">
                              <div class="render-name">
                                {{ asset.title }}
                              </div>
              </router-link>


              <!-- Panoramas -->
              <router-link
                v-if="viewType === 'panoramas' || viewType === 'virtual-tour' || viewType === 'balcony-views'" :to="{
                    name: 'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }" class="p-link">
                                <img
                                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                                  class="img-fluid">
                                <div class="render-name">
                                  {{ asset.title }}
                                </div>
                              </router-link>

                              <!-- Floorplans -->
                              <router-link v-if="viewType === 'floorplans'" :to="{
                    name: 'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }" class="f-link">
                <img
                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ViewBotNav />
  <OffCanvas />
</template>

<script>
import ViewTopNav from '@/components/ViewTopNav.vue';
import ViewBotNav from '@/components/ViewBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global';
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetView",
  props: [
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    ViewTopNav,
    ViewBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      videoStatus: false,
      assetNames: null,
      assetUrls: null
    }
  },
  created() {
    if (this.assetType === 'vista-penthouse') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.vistaPenthouseAsset.videos
        this.assetNames = this.theme.vistaPenthouseAsset.videosName
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.vistaPenthouseAsset.panoramas
        this.assetNames = this.theme.vistaPenthouseAsset.panoramasName
        this.assetUrls = this.theme.vistaPenthouseAsset.panoramasUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.vistaPenthouseAsset.renders
        this.assetNames = this.theme.vistaPenthouseAsset.rendersName
        this.assetUrls = this.theme.vistaPenthouseAsset.rendersUrl
      }
    }

    // Video type
    if (this.viewType === 'videos') {
      BunnyNetService.getVideos(this.lid, this.collectionId)
        .then(response => {
          // Remap array (inject asset names)
          console.log(response)
          let j = 0;
          const menuItems = response.data.items.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1) {
              return {
                guid: item.guid,
                videoLibraryId: item.videoLibraryId,
                thumbnailFileName: item.thumbnailFileName,
                ObjectName: item.ObjectName,
                title: this.assetNames[j],
                id: j++
              };
            } else {
              return {
                guid: item.guid,
                videoLibraryId: item.videoLibraryId,
                thumbnailFileName: item.thumbnailFileName,
                ObjectName: item.ObjectName,
                title: unslugify(item.title.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
                id: j++
              };
            }
          });

          // Custom order array
          if (this.assetOrder && this.assetOrder.length >= 1) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
          } else {
            this.assetTypes = menuItems;
          }

          this.videoStatus = true
        })
        .catch(error => {
          console.log(error)
        })
      // Renders, Panoramas, Floorplans type
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
        .then(response => {
          let j = 0;
          // Remap array
          console.log(response)
          const menuItems = response.data.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1) {
              return {
                Guid: item.Guid,
                ObjectName: unslugify(item.ObjectName),
                LinkName: item.ObjectName,
                Path: item.Path,
                IsDirectory: item.IsDirectory,
                StorageZoneName: item.StorageZoneName,
                title: this.assetNames[j],
                url: this.assetUrls[j],
                id: j++
              };
            } else {
              return {
                Guid: item.Guid,
                ObjectName: unslugify(item.ObjectName),
                LinkName: item.ObjectName,
                Path: item.Path,
                IsDirectory: item.IsDirectory,
                StorageZoneName: item.StorageZoneName,
                title: unslugify(item.ObjectName.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
                url: [],
                id: j++
              };
            }
          });

          // Custom order array
          if (this.assetOrder && this.assetOrder.length >= 1) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
          } else {
            this.assetTypes = menuItems
          }
        })
    }
  },
  methods: {
    reorder: function (data_array, real_order) {
      var i, d = {}, result = [];
      for (i = 0; i < data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for (i = 0; i < real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
